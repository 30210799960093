// ---------------------------------------------------------------------------------------------------------------------
// Theme: Dark
// ---------------------------------------------------------------------------------------------------------------------
$theme-dark: (
	// General.
	primary-text:           #dcdcdc,
	primary-background:     #1f2123,
	link-text:              #0079ff,
	link-text-hover:        #35a7e8,
	contrast:               transparentize(#fff, 0.9),

	// Sections.
	section-border:         #333,
	section-shadow:         #000,
	section-background:     #1f2123,

	// Header.
	header-text:            #dcdcdc,
	header-text-light:      #aaa,
	header-background:      #1f2123,
	header-border:          transparentize(#fff, 0.8),
	header-shadow:          transparentize(#000, 0.9),
	header-link-text:       #dcdcdc,
	header-link-icon:       #cbcbcb,
	header-link-hover:      #fff,

	// Footer.
	footer-border:          transparentize(#000, 0.8),
	footer-text:            #666,
	footer-link-text:       #777,
	footer-link-text-hover: #333,

	// Component: Profile
	component-profile-shadow:     transparentize(#000, 0.95),
	component-profile-background: #1f2123,
	component-profile-border:     #555,

	// Component: Project
	component-project-background:         #1f2123,
	component-project-background-default: #333,
	component-project-border:             #333,
	component-project-name:               #aaa,
	component-project-link-text:          #ccc,
	component-project-link-hover:         #eee,
	component-project-language:           #aaa,
	component-project-label-background:   #1f2123,
	component-project-label-text:         #aaa,
	component-project-label-shadow:       transparentize(#000, 0.9),

	// Component: Skill
	component-skill-background:           #1f2123,
	component-skill-border:               #333,
	component-skill-name-text:            #aaa,
	component-skill-level-bar-background: #444,
	component-skill-level-bar-foreground: #bf4044,
	component-skill-background-open:      #292c2e,
);
