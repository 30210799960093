// ---------------------------------------------------------------------------------------------------------------------
// Theme: Light
// ---------------------------------------------------------------------------------------------------------------------
$theme-light: (
	// General.
	primary-text:           #000,
	primary-background:     #fff,
	link-text:              #0079ff,
	link-text-hover:        #35a7e8,
	contrast:               transparentize(#000, 0.9),

	// Sections.
	section-border:         #ddd,
	section-shadow:         transparentize(#000, 0.95),
	section-background:     #fcfcfc,

	// Header.
	header-text:            #333,
	header-text-light:      #777,
	header-background:      #fff,
	header-border:          transparentize(#000, 0.8),
	header-shadow:          transparentize(#000, 0.9),
	header-link-text:       #333,
	header-link-icon:       #666,
	header-link-hover:      #000,

	// Footer.
	footer-border:          transparentize(#000, 0.8),
	footer-text:            #666,
	footer-link-text:       #777,
	footer-link-text-hover: #333,

	// Component: Profile
	component-profile-shadow:     transparentize(#000, 0.95),
	component-profile-background: #f3f3f3,
	component-profile-border:     #ddd,

	// Component: Project
	component-project-background:         #fff,
	component-project-background-default: #f3f3f3,
	component-project-border:             #ddd,
	component-project-name:               #444,
	component-project-link-text:          #666,
	component-project-link-hover:         #333,
	component-project-language:           #999,
	component-project-label-background:   #fff,
	component-project-label-text:         #333,
	component-project-label-shadow:       transparentize(#000, 0.9),

	// Component: Skill
	component-skill-background:           #fff,
	component-skill-border:               #ddd,
	component-skill-name-text:            #444,
	component-skill-level-bar-background: #f3f3f3,
	component-skill-level-bar-foreground: #80cdff,
	component-skill-background-open:      #f3f3f3,

);
