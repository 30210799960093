// ---------------------------------------------------------------------------------------------------------------------
// Themes:
// ---------------------------------------------------------------------------------------------------------------------
@import 'themes/light';
@import 'themes/dark';
$themes: (
	default: $theme-light,
	light:   $theme-light,
	dark:    $theme-dark
);

// ---------------------------------------------------------------------------------------------------------------------
// Layout:
// ---------------------------------------------------------------------------------------------------------------------
$page-width:                 650px;

$layout-top-margin:          25px;

$layout-padding:             15px;
$layout-section-margin:      15px;

$layout-header-z:            10;
$layout-header-height:       40px;
$layout-header-padding:      5px;

$layout-footer-height:       18px;
$layout-footer-padding:      2px 5px;

$layout-project-width:       300px;
$layout-project-image:       120px;

$layout-profile-padding:     25px;

$layout-skill-width:         120px;
$layout-skill-icon-size:     100px;
$layout-skill-level-height:  3px;

$layout-overlay-z:           5;

// ---------------------------------------------------------------------------------------------------------------------
// Animation:
// ---------------------------------------------------------------------------------------------------------------------
$animation-speed:            0.15s;
$animation-speed-link:       0.15s;