@import '../theme';
@import '../mixin/theme';

// ---------------------------------------------------------------------------------------------------------------------
// Profile:
// ---------------------------------------------------------------------------------------------------------------------

.profile {
	position: relative;
	margin-bottom: -$layout-padding;
	width: 100vw;

	box-sizing: border-box;

	padding: $layout-profile-padding;
}

.profile-content {
	position: relative;
	display: flex;
	flex-direction: row;
	text-align: center;
	z-index: 2;

	justify-content: center;

	> * {
		box-shadow: theme-get(component-profile-shadow) 0 1px 1px;
	}
}

// Profile banner.
.profile {
	background-color: #eee;
	background-size: cover;
	background-repeat: no-repeat;

	& > .profile-banner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 65%;
	}

	&::after {
		content: '';

		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 35%;
		z-index: 1;

		border-top: transparent 1px solid;

		@include themed((
			border-color: component-profile-border,
			background-color: primary-background
		));
	}
}

// Profile image and information.
.profile .profile-picture {
	border: transparent 1px solid;
	border-right: none;

	font-size: 0;

	@include themed((border-color: component-profile-border));

	&, & picture, & picture > img {
		height: 100px;
		width: 100px;
	}
}

.profile .profile-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;

	padding: 0 $layout-profile-padding;

	border: transparent 1px solid;
	@include themed((
			border-color: component-profile-border,
			background: component-profile-background
	));

}

.profile .profile-info .profile-name {
	align-self: flex-start;
	font-size: 1.1em;
}

.profile .profile-info .profile-description {
	font-size: 0.9em;
}
