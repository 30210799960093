// ---------------------------------------------------------------------------------------------------------------------
// Mixin: theme
// ---------------------------------------------------------------------------------------------------------------------

$theme-mixin-unsafe: false;

@function theme-get($variable, $theme: null) {
	$theme-vars: null;

	// Get the variable map.
	@if $theme == null {
		$theme-vars: map-get($themes, 'default');
	} @else {
		@if not map-has-key($themes, $theme) {
			@error "Theme does not exist: '#{$theme}'";
		}

		$theme-vars: map-get($themes, $theme);
	}

	// Error if the variable doesn't exist in the map.
	@if not map-has-key($theme-vars, $variable) {
		@if theme-mixin-unsafe {
			@warn "Variable does not exist: '#{$variable}' in '#{$theme}'";
			@return #f0f;
		} @else {
			@error "Variable does not exist: '#{$variable}' in '#{$theme}'";
		}
	}

	// Return the variable.
	@return map-get($theme-vars, $variable);
}

@mixin themed-property($property, $variable) {
	@each $theme in map-keys($themes) {
		// Automatic theme.
		@if $theme == 'dark' /* or $theme == 'light' */ { // We ignore light here, since it's the default.
			@media (prefers-color-scheme: $theme) {
				#{$property}: theme-get($variable, $theme);
			}
		}

		// Default theme.
		@if $theme == 'default' {
			#{$property}: theme-get($variable, $theme);
		}

		// Any other theme.
		// We're not using theme selectors for this website.
		//@if $theme != 'default' {
		//	.#{$theme} &,
		//	&.#{theme} {
		//		#{$property}: theme-get($variable, $theme);
		//	}
		//}
	}
}

@mixin themed($properties) {
	@each $property in map-keys($properties) {
		@include themed-property($property, map-get($properties, $property));
	}
}